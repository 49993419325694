@import url(https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700&display=swap);
/* Variables */
/* Utilities */
.mb-1 {
  margin-bottom: 1rem; }

.mb-1-5 {
  margin-bottom: 1.5rem; }

.mb-2 {
  margin-bottom: 2rem; }

.mt-1 {
  margin-top: 1rem; }

.ml-auto {
  margin-left: auto !important; }

.mr-1 {
  margin-right: 1rem; }

.text-blue {
  color: #0DD8FF; }

.text-red {
  color: #e94444; }

.log-date {
  font-size: .75rem;
  font-weight: 700; }

.log-set {
  position: relative;
  display: flex;
  padding: .25rem 0;
  border-bottom: 1px solid #D6D6D6; }
  .log-set__no {
    font-weight: 700;
    flex: 1 1; }
    .log-set__no:after {
      content: ': '; }
  .log-set__stat {
    flex: 1 1; }
    .log-set__stat > span {
      font-size: .75rem;
      font-weight: bold; }

.section-title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem; }
  @media screen and (min-width: 992px) {
    .section-title {
      font-size: 1.25rem; } }
  .section-title--icon {
    display: flex;
    align-items: center; }
    .section-title--icon svg {
      width: 24px;
      height: 24px;
      fill: #C5C5C5;
      margin-right: .5rem; }
    .nutrition-card .section-title--icon {
      border-bottom: 1px solid #EFEFEF;
      padding-bottom: 1rem;
      margin-bottom: .5rem; }
  .section-title .icon-dropdown.right {
    position: absolute;
    right: .75rem;
    top: 14px;
    z-index: 5; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  scroll-behavior: smooth; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  font-family: 'Open Sans', sans-serif;
  color: #707070;
  font-size: 16px;
  width: 100vw;
  overflow-x: hidden; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

b {
  font-weight: 700; }

/* Typography */
p {
  line-height: 1.5;
  margin-bottom: 1rem; }

h1 {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase; }

/* Buttons */
.btn-float-container {
  padding-bottom: 80px; }

.btn-float-bottom {
  position: fixed;
  display: block;
  bottom: 80px;
  left: 0;
  width: 100%;
  height: auto;
  padding: 1rem;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 30%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
  transition: left .1s ease-in-out; }
  @media screen and (min-width: 768px) {
    .btn-float-bottom {
      position: absolute;
      bottom: 0;
      padding: 2rem; } }
  .site-main.is-open .btn-float-bottom {
    left: -111px; }

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #979797;
  border-radius: 8px;
  height: 40px;
  border: none;
  font-weight: 500;
  color: white;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  padding: 0 2rem; }
  .btn:disabled {
    opacity: .5;
    cursor: not-allowed; }
  .btn--inverse {
    background-color: transparent;
    border: 1px solid #979797;
    color: #979797; }
  .btn--block {
    width: 100%; }
    .modal__actions .btn--block {
      grid-column: 1 / span ll; }
  .btn--green {
    background-color: #3CDA3C; }
    .btn--green span {
      background-color: #24bf24; }
  .btn--red {
    background-color: #e94444; }
  .btn--text {
    background: transparent;
    color: #979797; }
  .btn--white {
    background-color: white;
    color: #979797; }
  .btn--orange {
    background-color: #D5DDCC; }

.option-buttons {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem; }
  .option-buttons .btn {
    width: 100%; }

.icon-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: #ECECEC;
  border: none;
  border-radius: 50%;
  color: #C5C5C5;
  font-size: 1.75rem;
  padding: 0; }
  .icon-btn svg {
    flex: none;
    width: 12px;
    height: 12px;
    fill: #979797; }
  .icon-btn--lrg {
    width: 42px;
    height: 42px; }
    .icon-btn--lrg svg {
      width: 24px;
      height: 24px; }
  .icon-btn--orange {
    background-color: #D5DDCC; }
    .icon-btn--orange svg {
      fill: white; }

.favourite-btn {
  position: absolute;
  bottom: .5rem;
  left: .5rem;
  border: none;
  background: gray;
  background: radial-gradient(circle, rgba(128, 128, 128, 0.3) 0%, rgba(42, 193, 42, 0) 100%);
  padding: 2px;
  border-radius: 50%; }
  .favourite-btn svg {
    width: 28px;
    height: 28px;
    fill: #fef29b; }
  .favourite-btn.is-active svg {
    -webkit-animation: growShrink .2s ease-in-out forwards;
            animation: growShrink .2s ease-in-out forwards; }
  .recipe-detail .favourite-btn {
    bottom: auto;
    top: 1rem;
    left: 1rem; }
  .video-library-item__image.recipe .favourite-btn {
    top: .5rem;
    bottom: auto; }

@-webkit-keyframes growShrink {
  0% {
    width: 28px;
    height: 28px; }
  50% {
    width: 30px;
    height: 30px; }
  100% {
    width: 28px;
    height: 28px; } }

@keyframes growShrink {
  0% {
    width: 28px;
    height: 28px; }
  50% {
    width: 30px;
    height: 30px; }
  100% {
    width: 28px;
    height: 28px; } }
.mood-buttons {
  position: relative;
  display: flex;
  justify-content: space-between; }

.mood-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  width: 62px;
  height: 62px;
  border-radius: 50%; }
  .mood-btn svg {
    fill: #C5C5C5;
    width: 50px;
    height: 50px; }
  .mood-btn.is-selected {
    border: 2px solid #D5DDCC; }
    .mood-btn.is-selected svg {
      fill: #979797; }

.nav-trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none; }
  .nav-trigger svg {
    width: 34px;
    height: 34px;
    fill: #979797; }

/* Forms */
.form-item {
  position: relative;
  margin-bottom: 1.5rem; }
  .form-item label {
    display: block;
    font-size: 1rem;
    margin-bottom: .5rem; }

.form-link {
  position: relative;
  display: block;
  text-align: center;
  padding: 1.5rem 0 1rem;
  text-decoration: none;
  font-weight: 500;
  color: #D5DDCC; }

.form-input-link {
  display: block;
  font-size: .75rem;
  text-align: right;
  margin-top: .5rem;
  color: #D5DDCC;
  text-decoration: none; }

.form-error {
  position: relative;
  display: block;
  padding: .5rem;
  background-color: rgba(233, 68, 68, 0.2);
  border: 1px solid #e94444;
  border-radius: 4px;
  margin-bottom: 1rem; }
  input + .form-error {
    margin-top: .5rem; }

.form-success {
  position: relative;
  display: block;
  padding: .5rem;
  background-color: rgba(60, 218, 60, 0.5);
  border: 1px solid #3CDA3C;
  border-radius: 4px;
  margin-bottom: 1rem;
  color: white; }
  input + .form-success {
    margin-top: .5rem; }

input[type=email],
input[type=text],
input[type=password],
input[type=date],
input[type=number],
select,
textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding: 0 1rem;
  background-color: white;
  border: 1px solid #C5C5C5;
  box-shadow: none;
  -webkit-appearance: none; }

select {
  padding: .5rem 2rem .5rem .5rem;
  background-image: url("/assets/dropdown.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: calc(100% - 10px) 50%; }
  select .standout-option {
    font-weight: 500;
    color: #D5DDCC; }

.input-addon {
  position: relative;
  display: flex; }
  .input-addon input {
    flex: 1 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-addon .btn {
    flex: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-addon + .form-error, .input-addon + .form-success {
    margin-top: .5rem; }

.stripe-payment-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 8px;
  padding: 0 1rem;
  margin-bottom: 1rem;
  margin-top: .5rem; }
  .stripe-payment-wrapper > div {
    flex: 1 1; }

.select-tabs {
  position: relative;
  display: flex; }
  .select-tabs__item {
    position: relative;
    flex: 1 1; }
    .select-tabs__item input[type=radio] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
    .select-tabs__item label {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      color: white;
      font-weight: 700;
      font-size: 1.15rem;
      border: 2px solid white; }
      .select-tabs--alt .select-tabs__item label {
        border-color: #D5DDCC;
        color: #D5DDCC; }
    .select-tabs__item:first-of-type label {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
    .select-tabs__item:last-of-type label {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px; }
    .select-tabs__item.is-selected label {
      background-color: white;
      color: #D5DDCC; }
      .select-tabs--alt .select-tabs__item.is-selected label {
        color: white;
        background-color: #D5DDCC; }

/* Layout Elements */
.main-container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: #EFEFEF;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .main-container {
      background-color: #EFEFEF;
      padding-top: 100px; } }
  .main-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 94px);
    height: 100%;
    background: transparent;
    transition: all .3s ease-in-out;
    pointer-events: none;
    z-index: 100; }
  .main-container.overlay:after {
    background: rgba(151, 151, 151, 0.2);
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, rgba(151, 151, 151, 0.2), rgba(151, 151, 151, 0)); }

.basic-container {
  position: relative;
  padding: 1rem 0;
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 1rem; }
  .basic-container .section-title {
    padding-left: 1rem; }
  .basic-container--trans {
    background-color: rgba(255, 255, 255, 0.5);
    padding-top: 0;
    padding-bottom: 1rem; }
    .basic-container--trans .section-title {
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: white;
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem; }

.home-container {
  position: relative;
  width: 100%;
  border-radius: 30px;
  padding: 2rem 1.5rem;
  background-color: white;
  max-width: 960px;
  min-height: calc(100vh - 152px);
  overflow: hidden; }
  .home-container h2 {
    font-size: 1.15rem;
    font-weight: 500; }
  .page-header + .home-container {
    margin-top: -20px; }
  @media screen and (min-width: 768px) {
    .home-container {
      border-radius: 30px;
      padding: 2rem;
      min-height: auto;
      box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); } }
  @media screen and (min-width: 1200px) {
    .home-container {
      padding: 2rem 3rem; } }
  .home-container--superset:before {
    content: '';
    position: absolute;
    top: -30px; }

.internal-container {
  position: relative;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-top: 2rem;
  padding: 2rem 1.5rem;
  border-radius: 30px;
  background-color: #D5DDCC;
  color: white; }
  .internal-container.grey {
    background-color: #ECECEC;
    color: #979797; }
  .internal-container label {
    font-weight: 700;
    font-size: 1.15rem; }
  .internal-container input {
    border: none; }
  @media screen and (min-width: 768px) {
    .internal-container {
      border-radius: 30px; } }

.site-main {
  position: relative;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  min-height: 100vh;
  background: #EFEFEF;
  left: 0;
  padding: 1rem 1rem 90px 1rem;
  transition: all .1s ease-in-out; }
  .site-main.is-open {
    left: -111px; }

.page-header {
  position: relative;
  background-color: #D5DDCC;
  padding: 2rem 1rem 10rem;
  margin-bottom: -9rem;
  width: 100vw;
  left: -1rem;
  top: -1rem; }
  @media screen and (min-width: 992px) {
    .page-header {
      left: calc(calc(calc(calc(100vw - 980px) / 2) * -1) - 1rem);
      top: -2.2rem; } }
  .page-header__inner {
    position: relative;
    display: flex;
    max-width: 960px;
    margin: 0 auto; }
    .page-header__inner button {
      border: none;
      background-color: transparent;
      width: 24px;
      height: 24px;
      flex: none;
      z-index: 1; }
      .page-header__inner button svg {
        width: 24px;
        height: 24px;
        fill: white;
        pointer-events: none; }
    .page-header__inner h1 {
      position: relative;
      font-weight: 500;
      text-transform: none;
      width: 100vw;
      text-align: center;
      left: -12px;
      color: white; }
      .page-header__inner h1 select {
        display: inline;
        width: auto;
        padding: .5rem 2rem .5rem .5rem;
        height: auto;
        background: transparent;
        border: none;
        font-weight: 700;
        font-size: 1.4rem;
        color: #D5DDCC;
        background-image: url("/assets/dropdown.svg");
        background-repeat: no-repeat;
        background-size: 15px 15px;
        background-position: calc(100% - 10px) 50%;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        border: 1px solid white;
        margin-left: .5rem; }

@media screen and (min-width: 992px) {
  .split-layout {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem; } }

.grid-2-col {
  display: grid;
  grid-gap: 1rem; }
  .grid-2-col .card {
    margin-bottom: 0; }
  @media screen and (min-width: 600px) {
    .grid-2-col {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto; } }

/* Navigation */
.site-nav {
  position: relative;
  left: 0;
  transition: all .1s ease-in-out; }
  .site-nav.is-open {
    left: -94px; }
  .site-nav__main {
    position: fixed;
    bottom: .5rem;
    left: .5rem;
    display: flex;
    align-items: center;
    width: calc(100% - 1rem);
    height: 80px;
    padding: 0 1rem;
    background-color: white;
    border-radius: 1rem;
    z-index: 10;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12);
    transition: all .1s ease-in-out; }
    .is-open .site-nav__main {
      left: -94px; }
    @media screen and (min-width: 992px) {
      .site-nav__main {
        top: 0;
        bottom: auto;
        border-radius: 0;
        background-color: white;
        width: 100%;
        left: 0; } }
  .site-nav__logo {
    display: none; }
    @media screen and (min-width: 992px) {
      .site-nav__logo {
        display: inline-block; } }
  .site-nav__logo {
    position: relative; }
    .site-nav__logo img {
      position: relative;
      width: 148px;
      height: auto; }
  .site-nav__primary {
    position: relative;
    flex: 1 1;
    display: flex;
    justify-content: space-around; }
    @media screen and (min-width: 992px) {
      .site-nav__primary {
        display: none; } }
  .site-nav__trigger {
    margin-left: auto;
    display: none; }
    @media screen and (min-width: 992px) {
      .site-nav__trigger {
        display: block; } }

.site-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: -94px;
  top: 0;
  width: 94px;
  height: 100vh;
  margin: 0;
  padding: 2rem 0 1rem;
  list-style: none;
  overflow-y: auto;
  z-index: 0;
  background-color: #ECECEC;
  box-shadow: inset 7px 0 9px -7px rgba(60, 66, 87, 0.32), inset 7px 0 9px -7px rgba(167, 167, 167, 0.12);
  transition: right .1s ease-in-out; }
  .site-nav.is-open .site-menu {
    right: 0; }

.menu-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  margin-bottom: 1rem;
  text-decoration: none;
  background-color: transparent;
  border: none; }
  .menu-item--end {
    margin-top: auto;
    margin-bottom: 0; }
  .menu-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 14px;
    background-color: white;
    margin-bottom: .75rem; }
    .menu-item__icon svg {
      width: 24px;
      height: 24px;
      fill: #979797; }
    .is-active .menu-item__icon {
      background-color: #979797; }
      .is-active .menu-item__icon svg {
        fill: white; }
  .menu-item__label {
    color: #979797;
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 500; }

.menu-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
  border-radius: 1rem; }
  .menu-icon.is-active {
    background-color: #D5DDCC; }
    .menu-icon.is-active svg {
      fill: white; }
  .menu-icon svg {
    width: 28px;
    height: 28px;
    fill: #979797; }
  .menu-icon span {
    display: none;
    text-align: center;
    font-weight: 600;
    color: #979797;
    font-size: .85rem; }

/* Action Panel / Items */
.action-panel {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  margin-bottom: 1rem; }
  @media screen and (min-width: 600px) {
    .action-panel {
      grid-template-columns: repeat(4, 1fr); } }

.action-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  background-color: #ababb5;
  overflow: hidden;
  border-radius: 8px;
  text-decoration: none;
  transition: all .3s ease-in-out; }
  .action-item:hover {
    background-color: #D5DDCC; }
    .action-item:hover svg {
      fill: white; }
    .action-item:hover .action-item__title {
      color: white; }
  .action-item svg {
    display: none;
    position: absolute;
    top: -28px;
    left: -20px;
    width: 130px;
    height: 130px;
    fill: white;
    transform: rotate(-25deg);
    transition: all .3s ease-in-out; }
  .action-item__title {
    font-weight: 500;
    font-size: 1.15rem;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    letter-spacing: 1px;
    transition: all .3s ease-in-out; }

.expander {
  position: relative;
  text-decoration: none;
  color: #707070; }
  .expander__heading {
    position: relative;
    display: flex;
    align-items: center; }
    .expander__heading h2 {
      flex: 1 1;
      font-size: 1.15rem;
      line-height: 1;
      margin: 0; }
    .expander__heading span {
      flex: 1 1; }
    .expander__heading a {
      color: #D5DDCC;
      text-decoration: none; }
  .expander__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out; }
    .expander__content .btn {
      margin-top: 1rem; }
    .expander__content.is-open {
      padding-top: 1rem;
      max-height: 100000000px; }

.list-item {
  position: relative;
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
  .list-item span {
    margin-left: auto; }

/* Cards */
@media screen and (min-width: 768px) {
  .card-grid-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem; }
    .card-grid-layout .card {
      margin-bottom: 0; } }

.card {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 260px;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 1rem; }
  .card__title {
    position: relative;
    flex: none;
    padding: 1rem;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px; }

.card-option {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem; }

.card-corner-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: .5rem;
  right: .5rem; }
  .card-corner-icon svg {
    width: 24px;
    height: 24px;
    fill: #C5C5C5; }

.profile-card {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 1rem; }
  @media screen and (min-width: 992px) {
    .profile-card {
      height: 238px; } }
  .profile-card__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    justify-content: center;
    align-items: center; }
  .profile-card__avatar {
    position: relative;
    flex: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #D6D6D6; }
    .profile-card__avatar input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
    .profile-card__avatar img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .profile-card__avatar-es {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .profile-card__avatar-es div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%; }
      .profile-card__avatar-es svg {
        width: 44px;
        height: 44px;
        fill: #707070; }
      .profile-card__avatar-es p {
        font-size: .65rem;
        margin-top: 1rem;
        margin-bottom: 0; }
  .profile-card__main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; }
  .profile-card__name {
    position: relative;
    align-items: center;
    flex: 1 1;
    padding: 1rem 1rem 0;
    text-align: center; }
    .profile-card__name p {
      margin: 0 0 .75rem 0;
      font-size: 1.25rem;
      line-height: 1; }
      .profile-card__name p:first-of-type {
        opacity: .6; }
      .profile-card__name p:last-of-type {
        font-weight: 600; }
  .profile-card__action {
    position: absolute;
    align-items: center;
    flex: none;
    top: 1rem;
    right: .5rem;
    height: 50px;
    padding: 0 1rem;
    text-decoration: none;
    background-color: white; }
    .profile-card__action p {
      display: none;
      margin: 0;
      flex: 1 1;
      opacity: .75;
      font-size: .8rem;
      color: #707070; }
      @media screen and (min-width: 992px) {
        .profile-card__action p {
          font-size: 1rem; } }
    .profile-card__action svg {
      width: 22px;
      height: 22px;
      fill: #707070; }

.generic-card {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  text-decoration: none; }
  .generic-card__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .generic-card__icon {
    margin-bottom: .5rem; }
    .generic-card__icon svg {
      fill: white;
      width: 100%;
      height: 80px; }
  .generic-card__stat {
    font-size: 5rem;
    line-height: 1; }
  .generic-card__txt-lrg {
    line-height: 1;
    font-size: 1.5rem;
    font-weight: 500; }
  .generic-card__txt-sml {
    line-height: 1;
    font-size: 1.125rem; }

.category-card {
  justify-content: flex-start;
  color: #979797;
  font-weight: 500;
  height: 60px;
  min-height: auto;
  padding: 0 1rem;
  background-color: #ECECEC;
  border: none;
  box-shadow: none; }
  .category-card svg {
    width: 20px;
    height: 20px;
    fill: #979797;
    margin-left: auto; }
  @media screen and (min-width: 992px) {
    .category-card {
      height: 160px;
      justify-content: center; }
      .category-card svg {
        display: none; } }
  .category-card.favourites {
    background-color: #fef29b;
    color: #1a1a1a; }
    .category-card.favourites svg {
      fill: #1a1a1a; }

.recipe-category {
  justify-content: flex-start;
  color: #979797;
  font-weight: 500;
  height: 80px;
  min-height: auto;
  padding: 0 1rem;
  background-color: #ECECEC;
  border: none;
  box-shadow: none;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.05), 0 3px 6px 0 rgba(167, 167, 167, 0.05); }
  .recipe-category span {
    font-size: 1.5rem;
    text-transform: none;
    color: #979797;
    font-weight: 500;
    z-index: 1; }
  @media screen and (min-width: 992px) {
    .recipe-category {
      height: 160px;
      justify-content: center; } }

.activity-card {
  height: 250px; }

.plan-card {
  background-color: white;
  border-radius: 20px;
  overflow: hidden; }
  .plan-card--complete {
    display: flex;
    align-items: center;
    justify-content: center; }
  .plan-card__image {
    position: relative;
    width: 100%;
    height: 134px;
    border-radius: 20px;
    overflow: hidden;
    z-index: 1; }
    .plan-card__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    @media screen and (min-width: 768px) {
      .plan-card__image {
        height: 204px; } }
  .plan-card__header {
    position: relative;
    background-color: white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 2.5rem 1rem 1rem;
    margin-top: -1.5rem;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.01), 0 3px 6px 0 rgba(167, 167, 167, 0.04); }
  .plan-card__title {
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    margin-bottom: 1rem; }
  .plan-card__name {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: .75rem; }
    @media screen and (min-width: 992px) {
      .plan-card__name {
        font-size: 1.25rem; } }
  .plan-card__week {
    position: relative;
    color: #D5DDCC;
    font-size: .75rem; }
    .plan-card__week p {
      margin: 0; }
    @media screen and (min-width: 992px) {
      .plan-card__week {
        font-size: 1rem; } }
  .plan-card__itinerary {
    position: relative;
    padding: 0 1rem 1rem; }
  .plan-card__item {
    position: relative;
    display: flex;
    color: #979797;
    width: 100%;
    height: 42px;
    align-items: center;
    text-decoration: none;
    margin-top: .5rem; }
    .plan-card__item:not(:last-of-type) {
      border-bottom: 1px solid rgba(112, 112, 112, 0.12); }
    .plan-card__item p {
      font-size: .85rem;
      margin-bottom: 0;
      line-height: 1;
      font-weight: 600; }
      @media screen and (min-width: 992px) {
        .plan-card__item p {
          font-size: 1rem; } }
    .plan-card__item span {
      margin-left: auto; }
      .plan-card__item span svg {
        width: 22px;
        height: 22px;
        fill: #707070; }
    .plan-card__item.is-complete span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: #62D959; }
      .plan-card__item.is-complete span svg {
        fill: white;
        width: 14px;
        height: 14px; }

.chart-card .recharts-responsive-container {
  width: calc(100% + 3rem) !important;
  margin-left: -1.5rem;
  margin-top: 1rem; }
  .chart-card .recharts-responsive-container .recharts-xAxis,
  .chart-card .recharts-responsive-container .recharts-legend-wrapper {
    display: none !important; }
  .chart-card .recharts-responsive-container .recharts-tooltip-label,
  .chart-card .recharts-responsive-container .recharts-default-tooltip,
  .chart-card .recharts-responsive-container .recharts-tooltip-item-list li {
    color: #979797; }

.activity-calendar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: .5rem 1rem 1rem; }
  .activity-calendar__header {
    display: flex;
    align-items: center;
    flex: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: .5rem; }
  .activity-calendar__title {
    font-weight: 500; }
  .activity-calendar__month {
    margin-left: auto;
    border-radius: 4px;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.2);
    padding: .5rem; }
  .activity-calendar__body {
    flex: 1 1; }
  .activity-calendar__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 100%; }
  .activity-calendar__day {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .85rem;
    font-weight: 500; }
  .activity-calendar__date {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
    color: rgba(255, 255, 255, 0.75); }
    .activity-calendar__date.has-activity {
      color: #D5DDCC; }
      .activity-calendar__date.has-activity span {
        z-index: 1; }
      .activity-calendar__date.has-activity:before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        transform: translateX(-50%), translateY(-50%);
        background-color: #fff;
        background: radial-gradient(circle, #fff 0, rgba(255, 255, 255, 0) 100%);
        border-radius: 50%;
        box-shadow: 0 0 15px -4px #fff;
        border-radius: 50%; }

.nutrition-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: 1rem;
  padding: 1rem; }

.macro-breakdown {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 216px; }
  .nutrition-card .macro-breakdown {
    flex: 1 1;
    height: 130px;
    justify-content: center; }
  .macro-breakdown__kcal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    padding: 0 1rem; }
    .macro-breakdown__kcal p {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0; }
      .macro-breakdown__kcal p span {
        font-size: 1.15rem; }
      .macro-breakdown__kcal p.alt {
        font-size: 1rem;
        font-weight: normal;
        text-align: center; }
  .macro-breakdown__pcf {
    position: relative;
    display: flex;
    padding: 0;
    height: 56px;
    justify-content: space-between;
    text-decoration: none; }
    @media screen and (min-width: 992px) {
      .macro-breakdown__pcf {
        margin: 2rem; } }
  .macro-breakdown__item {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: white; }
    .nutrition-card .macro-breakdown__item {
      color: #979797 !important;
      background-color: white !important; }
    .macro-breakdown__item p {
      margin-bottom: 0;
      font-size: 1.5rem;
      line-height: 1; }
      @media screen and (min-width: 992px) {
        .macro-breakdown__item p {
          font-size: 2rem; } }
    .macro-breakdown__item span {
      display: block;
      font-size: .65rem;
      margin-top: .25rem;
      color: #D5DDCC; }
      @media screen and (min-width: 992px) {
        .macro-breakdown__item span {
          font-size: .85rem; } }
    .macro-breakdown__item:not(:last-of-type) {
      margin-right: .5rem; }
    .macro-breakdown__item.protein {
      background-color: #D5DDCC; }
    .macro-breakdown__item.carbs {
      background-color: #0DD8FF; }
    .macro-breakdown__item.fats {
      background-color: #3CDA3C; }
  .macro-breakdown__options {
    position: absolute;
    top: 0;
    right: 0; }
  .macro-breakdown-es {
    position: relative;
    display: block;
    padding: 1rem; }
    .macro-breakdown-es h2 {
      font-size: .85rem;
      font-weight: 600;
      margin-bottom: 1rem; }
    .macro-breakdown-es p {
      font-size: .85rem; }
    .macro-breakdown-es__action {
      padding-top: 1rem; }

.upload-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 2px dashed #D5DDCC;
  padding: 1rem;
  margin-bottom: 1.5rem; }
  .upload-box__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .upload-box__inner svg {
      width: 60px;
      height: 60px;
      fill: #D5DDCC;
      margin-bottom: 1rem; }
  .upload-box__title {
    font-weight: 700;
    font-size: 1.15rem;
    color: #C5C5C5;
    margin-bottom: 0;
    transition: all .3s ease-in-out; }
    .upload-box:hover .upload-box__title {
      color: #D5DDCC; }
  .upload-box input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }

.upload-library {
  position: relative; }
  .upload-library__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
    .upload-library__header select {
      margin-left: auto;
      flex: none;
      display: inline;
      width: auto;
      background-image: none;
      background-color: #D5DDCC;
      color: white;
      font-weight: 700;
      border: none;
      padding: .5rem 1rem;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .upload-library__header h2 + div {
      margin-left: auto; }
      .upload-library__header h2 + div input {
        border: none;
        background-color: #D5DDCC;
        color: white;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase; }
  .upload-library__section {
    position: relative;
    margin-bottom: 1.5rem; }
    .upload-library__section h3 {
      margin-bottom: 1rem; }
  .upload-library__grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .85rem; }
    @media screen and (min-width: 768px) {
      .upload-library__grid {
        grid-template-columns: repeat(4, 1fr); } }
  .upload-library__item {
    position: relative;
    display: block;
    width: 100%; }
    .upload-library__item img {
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .upload-library__item .delete {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: .5rem;
      right: .5rem;
      border: none;
      background-color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.4); }
      .upload-library__item .delete svg {
        width: 20px;
        height: 20px;
        fill: #979797;
        transition: all .3s ease-in-out; }
      .upload-library__item .delete:hover svg {
        fill: #D5DDCC; }

.grad-bg-orange {
  background: #ff9a9e;
  background: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%); }

.grad-bg-blue {
  background: #B2FEFA;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0ED2F7, #B2FEFA);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.grad-bg-green {
  background: #4AC29A;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #BDFFF3, #4AC29A);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.grad-bg-purple {
  background-color: #fad0c4;
  background-image: linear-gradient(315deg, #fad0c4 0%, #f1a7f1 74%); }

.grad-bg-yellow {
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%); }

/* Video Library */
.video-library {
  position: relative; }
  @media screen and (min-width: 768px) {
    .video-library {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem; } }
  @media screen and (min-width: 992px) {
    .video-library {
      grid-template-columns: 1fr 1fr 1fr; } }
  .video-library.recipe, .video-library.blog {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem; }

.video-library-item {
  position: relative;
  display: block;
  text-decoration: none;
  color: #979797; }
  .video-library-item:not(:last-of-type) {
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 1rem; }
    @media screen and (min-width: 768px) {
      .video-library-item:not(:last-of-type) {
        border-bottom: none; } }
  .video-library-item.detail {
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 1rem; }
    @media screen and (min-width: 992px) {
      .video-library-item.detail {
        border-bottom: none; } }
  .video-library-item.is-selected {
    border: 2px solid #0DD8FF; }
  .video-library-item__image {
    position: relative; }
    .video-library-item__image img {
      position: relative;
      display: block;
      width: 100%;
      border-radius: 8px; }
      .video-library-item__image img:before {
        content: '';
        display: block;
        padding-top: calc(100% * 2 / 3); }
    .video-library-item__image .icon-btn.add-to {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      background-color: #0DD8FF;
      color: white; }
    .video-library-item__image .recipe-lock {
      position: absolute;
      top: 1rem;
      right: 1rem; }
      .video-library-item__image .recipe-lock svg {
        width: 20px;
        height: 20px;
        fill: #D5DDCC; }
    .video-library-item__image.recipe {
      height: 178px; }
      .video-library-item__image.recipe img {
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .video-library-item__image.recipe-detail {
      height: 178px; }
      .video-library-item__image.recipe-detail img {
        height: 100%;
        object-fit: cover;
        object-position: center; }
      @media screen and (min-width: 768px) {
        .video-library-item__image.recipe-detail {
          height: 280px; } }
      @media screen and (min-width: 992px) {
        .video-library-item__image.recipe-detail {
          height: 300px; } }
    .blog .video-library-item__image {
      max-height: 219px;
      overflow: hidden;
      border-radius: 4px; }
  .video-library-item__info {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex; }
  .video-library-item__time {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 1rem;
    right: 1rem;
    color: white;
    font-weight: 500;
    font-size: .85rem;
    z-index: 1; }
    .video-library-item__time svg {
      width: 26px;
      height: 26px;
      fill: white;
      margin-right: .25rem; }
    .video-library-item__time span {
      margin-top: 2px; }
    .recipe-detail .video-library-item__time {
      position: relative;
      right: auto;
      bottom: auto;
      background-color: #D5DDCC;
      padding: .25rem .5rem;
      border-radius: 30px;
      flex: none; }
      .recipe-detail .video-library-item__time:not(:last-of-type) {
        margin-right: .5rem; }
      @media screen and (min-width: 768px) {
        .recipe-detail .video-library-item__time {
          padding: .5rem 1rem; } }
  .video-library-item__upgrade {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: bold;
    text-align: center; }
    .video-library-item__upgrade p {
      margin-bottom: 3rem; }
  .video-library-item__title {
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    padding: .75rem 0; }
    .detail .video-library-item__title, .video-library-item__title.detail {
      text-align: left; }
    .recipe .video-library-item__title {
      font-size: .85rem; }
      @media screen and (min-width: 1240px) {
        .recipe .video-library-item__title {
          font-size: 1rem; } }

/* Challenge Library */
.challenge-library {
  position: relative;
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .challenge-library {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr); } }

.challenge-card {
  position: relative;
  display: block;
  width: 100%;
  height: 220px;
  border-radius: .5rem;
  margin-bottom: 1rem;
  text-decoration: none;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .challenge-card {
      height: 320px; } }
  .challenge-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .challenge-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem 1rem;
    background-color: #D5DDCC;
    -webkit-clip-path: polygon(0 0, 100% 1rem, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 1rem, 100% 100%, 0 100%);
    transition: all .2s ease-in-out; }
    .challenge-card:hover .challenge-card__content {
      padding-bottom: 2rem;
      margin-top: -.5rem; }
  .challenge-card__title {
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1.5rem; }
  .challenge-card__start {
    display: inline;
    background-color: white;
    padding: 4px;
    color: #707070;
    font-weight: 500; }

/* Recipes */
.recipe-library {
  position: relative; }
  @media screen and (min-width: 768px) {
    .recipe-library {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem; } }

.recipe-item {
  position: relative;
  display: flex;
  text-decoration: none;
  margin-bottom: 1rem; }
  .recipe-item__image {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 8px;
    overflow: hidden; }
    .recipe-item__image img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .recipe-item__new {
    position: absolute;
    z-index: 1;
    background-color: #D5DDCC;
    color: white;
    top: 1rem;
    left: 1rem;
    padding: .5rem;
    font-weight: bold;
    font-size: .85rem;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .recipe-item__content {
    position: relative;
    flex: 1 1;
    padding: .25rem .5rem .5rem 1rem;
    color: #979797; }
    .recipe-item__content h3 {
      font-size: 1.15rem;
      line-height: 1.5;
      font-weight: 700; }
  .recipe-item__tags {
    margin-top: 1.5rem;
    font-weight: 700;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: .85rem;
    letter-spacing: 1px;
    line-height: 1.5; }
    .recipe-item__tags span {
      margin-right: 1rem; }

.recipe-detail-container {
  max-width: 960px;
  margin: 0 auto;
  padding: 1rem; }

.recipe-detail {
  position: relative; }
  .recipe-detail__nutrition {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem; }
    .recipe-detail__nutrition div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 700; }
      .recipe-detail__nutrition div span {
        font-weight: normal; }
      .recipe-detail__nutrition div:not(:last-of-type) {
        margin-right: 1.5rem; }
        @media screen and (min-width: 768px) {
          .recipe-detail__nutrition div:not(:last-of-type) {
            margin-right: 2rem; } }
  .recipe-detail__tabs {
    position: relative;
    display: flex;
    max-width: 600px;
    margin: 0 auto; }
  .recipe-detail__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    height: 40px;
    border: none;
    background-color: transparent;
    font-weight: 700; }
    .recipe-detail__tab.is-selected {
      border-bottom: 3px solid #D5DDCC; }
  .recipe-detail__content {
    display: none;
    position: relative;
    padding: 1rem;
    max-width: 600px;
    margin: 0 auto; }
    .recipe-detail__content.is-active {
      display: block; }
    .recipe-detail__content ul {
      list-style-type: disc; }
      .recipe-detail__content ul li {
        margin: .5rem 0; }
    .recipe-detail__content ol {
      list-style-type: decimal; }
      .recipe-detail__content ol li {
        margin: .5rem 0; }

.action-bar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px;
  background-color: #ECECEC;
  padding: 1rem; }
  .action-bar button:not(:last-of-type), .action-bar .btn:not(:last-of-type) {
    margin-right: 1rem; }

.video-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #979797;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: all .3s ease-in-out; }
  .video-overlay.is-open {
    opacity: 1;
    pointer-events: visible; }
  .video-overlay__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: none; }
    .video-overlay__close svg {
      width: 24px;
      height: 24px;
      fill: white; }
  .video-overlay__media {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 120px); }
    .video-overlay__media img, .video-overlay__media video {
      width: 100%;
      height: 100%; }
    .video-overlay__media iframe {
      width: 100%;
      height: 100%; }
  .video-overlay__action {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem; }

/* Knowledge Library */
.knowledge-library {
  position: relative; }
  @media screen and (min-width: 768px) {
    .knowledge-library {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem; } }
  @media screen and (min-width: 992px) {
    .knowledge-library {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr; } }

/* Login Panel */
.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background: #D5DDCC;
  z-index: 1; }
  .login-container__panel {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px; }
  .login-container__logo {
    position: relative;
    width: 227px;
    height: auto;
    z-index: 1; }
  .login-container__form {
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    flex: none;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem 1.5rem; }
    .login-container__form h1 {
      margin-bottom: 2rem; }
    .login-container__form .btn {
      margin-top: auto; }
      .login-container__form .btn + .btn {
        margin-top: 1rem; }
  @media screen and (min-width: 768px) {
    .login-container {
      align-items: center; }
      .login-container__panel {
        flex: none;
        height: 370px; }
      .login-container__form {
        width: 100%;
        max-width: 570px;
        border-radius: 30px;
        padding: 2rem;
        margin-bottom: 2rem; } }

/* Register Panel */
.register-container {
  position: relative;
  padding: 1rem;
  width: 100%;
  max-width: 720px;
  margin: 0 auto; }

/* Overlay / Modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(151, 151, 151, 0.35);
  transition: all .2s ease-in-out;
  z-index: 10; }
  .overlay.is-active {
    visibility: visible;
    opacity: 1; }
  .overlay.level-2 {
    z-index: 12; }

.modal {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 2rem);
  max-width: 768px;
  overflow-y: auto;
  background-color: white;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  padding: 2rem 1.5rem 1rem;
  transition: all .3s ease-in-out;
  z-index: 11; }
  .modal h2 {
    font-weight: 500;
    font-size: 1.15rem;
    margin-bottom: 1rem;
    line-height: 1.5; }
  .modal.is-open {
    bottom: 0; }
  .modal.level-2 {
    z-index: 13; }
  .modal__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem; }
    @media screen and (min-width: 768px) {
      .modal__actions {
        display: flex;
        justify-content: flex-end; }
        .modal__actions .btn:not(:last-of-type) {
          flex: none;
          margin-right: .75rem; } }
  .modal .delete {
    position: absolute;
    display: flex;
    top: 1rem;
    right: 1rem;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #ECECEC;
    border-radius: 50%; }
    .modal .delete svg {
      width: 24px;
      height: 24px;
      fill: #979797; }
  @media screen and (min-width: 768px) {
    .modal {
      left: calc(calc(100vw - 768px) / 2);
      max-height: calc(100vh - 6rem);
      border-radius: 30px; }
      .modal.is-open {
        bottom: 2rem; } }

/* Loader */
.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(213, 221, 204, 0.85);
  z-index: 100; }

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: lds-grid 1.2s linear infinite;
          animation: lds-grid 1.2s linear infinite; }

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s; }

@-webkit-keyframes lds-grid {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

@keyframes lds-grid {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }
/* GUIDE BUILD RELATED */
.guide-build {
  position: relative;
  margin-top: 1rem; }

.guide-week {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  color: #979797;
  margin-bottom: 1rem; }
  .guide-week__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #C5C5C5; }
    .guide-week__header p {
      line-height: 1;
      margin: 0; }
  .guide-week__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: .75rem;
    flex: none;
    margin-left: auto; }
  .guide-week__days {
    padding: 0 .5rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out; }
    .guide-week.is-open .guide-week__days {
      padding: 1rem .5rem;
      max-height: none; }

.guide-day {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #C5C5C5;
  margin-bottom: 1rem;
  color: #707070; }
  .guide-day__header {
    display: flex;
    align-items: center;
    padding: .75rem .5rem;
    border-bottom: 1px solid #C5C5C5; }
    .guide-day__header p {
      line-height: 1;
      margin: 0; }
  .guide-day__actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: .75rem;
    flex: none;
    margin-left: auto; }
  .guide-day__main {
    position: relative;
    padding: 0 .5rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out; }
    .guide-day.is-open .guide-day__main {
      max-height: none;
      padding: .75rem .5rem; }

.guide-exercise {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #C5C5C5;
  margin-bottom: 1rem;
  background-color: #ECECEC; }
  .guide-exercise__header {
    display: flex;
    align-items: center;
    padding: .75rem .5rem;
    border-bottom: 1px solid #C5C5C5; }
    .guide-exercise__header p {
      line-height: 1;
      margin: 0; }
  .guide-exercise__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: .75rem;
    flex: none;
    margin-left: auto; }
  .guide-exercise__main {
    position: relative;
    padding: 0 .5rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out; }
    .guide-day.is-open .guide-exercise__main {
      max-height: 10000px;
      padding: .75rem .5rem; }

.guide-set {
  position: relative;
  padding: .25rem; }
  .guide-set__header {
    position: relative;
    display: flex;
    align-items: center; }
    .guide-set__header p {
      line-height: 1;
      margin: 0; }
  .guide-set__actions {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: .75rem;
    float: none;
    margin-left: auto; }
    .guide-set__actions select {
      height: auto;
      border: none;
      background-color: transparent; }
  .guide-set__main {
    display: flex; }
    .guide-set__main .form-item label {
      font-size: .5rem; }
    .guide-set__main .form-item input {
      height: auto;
      background: transparent;
      padding: .5rem 0;
      border-bottom: 1px solid #C5C5C5;
      border-radius: 0; }
    .guide-set__main .form-item:not(:last-of-type) {
      margin-right: .5rem; }
    .guide-set__main.dropset {
      flex-direction: column; }

.dropset-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }
  .dropset-item .form-item {
    margin-bottom: 0; }
  .dropset-item p {
    line-height: 1;
    margin: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0DD8FF;
    color: white;
    border-radius: 50%;
    margin-right: .5rem;
    font-size: .75rem; }

.guide-add {
  position: relative;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
  font-weight: 700;
  background-color: transparent;
  border: 2px dashed #C5C5C5;
  border-radius: 8px;
  transition: all .3s ease-in-out; }
  .guide-add:hover {
    color: #D5DDCC;
    border-color: #D5DDCC; }
  .guide-add.small {
    padding: .75rem;
    font-size: .85rem; }
  .guide-add.extra-small {
    border-width: 1px;
    font-size: .5rem;
    padding: .5rem; }
  .guide-add.white {
    border-color: white;
    color: white; }
    .guide-add.white:hover {
      border-color: #ECECEC;
      color: #ECECEC; }

/* Workout Logging */
.workout-header {
  position: relative;
  padding: 2rem 1rem 4rem; }
  .workout-header__inner {
    position: relative;
    max-width: 720px;
    margin: 0 auto; }
    .workout-header__inner h1 {
      font-weight: 500;
      text-transform: none; }
  .workout-header__timer {
    position: absolute;
    top: -12px;
    right: 0;
    padding: 1rem;
    border-radius: 30px;
    background-color: #D5DDCC;
    color: white; }
  .workout-header__indicator {
    position: absolute;
    width: 100%;
    display: flex;
    bottom: -40px; }
    .workout-header__indicator span {
      flex: 1 1;
      position: relative;
      height: 3px;
      background-color: white; }
      .workout-header__indicator span:not(:last-of-type) {
        margin-right: 3px; }
      .workout-header__indicator span.is-solid {
        background-color: #D5DDCC; }
  .workout-header--challenge {
    z-index: 1;
    height: 166px;
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%); }
    @media screen and (min-width: 992px) {
      .workout-header--challenge {
        background: transparent; } }

.workout-label {
  position: relative;
  display: inline-block;
  margin-top: .5rem;
  padding: .5rem;
  background-color: #0DD8FF;
  color: white;
  font-weight: 700;
  font-size: .75rem;
  border-radius: 8px;
  z-index: 1; }

.superset-header {
  position: relative;
  width: 100%;
  color: white;
  background-color: #D5DDCC;
  padding: .5rem;
  border-radius: 8px;
  margin-bottom: .5rem; }

.workout-exercise {
  position: relative; }
  .workout-exercise__video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }
    .workout-exercise__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px; }
  .workout-exercise__video-controls {
    position: absolute;
    top: .5rem;
    right: .5rem;
    display: flex; }
    .workout-exercise__video-controls .icon-btn:last-of-type {
      margin-left: .5rem; }
    .workout-exercise__video-controls .icon-btn {
      background-color: #D5DDCC; }
      .workout-exercise__video-controls .icon-btn svg {
        fill: white; }
    .challenge-container .workout-exercise__video-controls {
      top: 7.5rem;
      right: 1rem; }
  .workout-exercise__title {
    position: relative;
    padding: .75rem 0 .5rem;
    font-weight: 500;
    font-size: 1.2rem;
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 1rem; }
    .challenge-container .workout-exercise__title {
      border: none; }

.workout-set {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #ECECEC;
  margin-bottom: 1rem; }
  .workout-set__header {
    position: relative;
    padding: .75rem .5rem;
    border-bottom: 1px solid #C5C5C5;
    font-weight: 700;
    color: #7e7e7e; }
  .workout-set__target, .workout-set__logging {
    display: flex;
    align-items: flex-end;
    padding: .75rem .5rem; }
    .workout-set__target > div + div, .workout-set__logging > div + div {
      margin-left: .5rem; }
    .workout-set__target label, .workout-set__logging label {
      display: block;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: .4rem; }
    .workout-set__target p, .workout-set__logging p {
      margin: 0;
      line-height: 1;
      font-weight: 500;
      color: #7e7e7e;
      font-size: 1.2rem; }
  .workout-set .input-group {
    position: relative;
    display: flex;
    align-items: center; }
    .workout-set .input-group input {
      width: 90px;
      margin-right: .5rem;
      border: none; }
    .workout-set .input-group p {
      margin: 0; }
  .workout-set__logging {
    border-top: 1px solid #C5C5C5; }
  .workout-set__timer {
    position: relative;
    padding: .75rem .5rem;
    border-top: 1px solid #C5C5C5; }
    .workout-set__timer label {
      display: block;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: .4rem; }

.workout-control {
  position: relative;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .workout-control .icon-btn {
    background-color: #D5DDCC; }
    .workout-control .icon-btn svg {
      fill: white; }
  .challenge-container .workout-control {
    padding: 1rem; }

.timer-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; }
  .timer-bar__container {
    position: relative;
    width: 100%;
    background-color: #C5C5C5;
    height: 14px;
    padding: 4px;
    border-radius: 30px;
    flex: 1 1; }
  .timer-bar__progress {
    position: relative;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    background-color: #D5DDCC;
    transition: all .1s; }
    .timer-bar__progress.is-on {
      background-color: #0DD8FF; }
    .timer-bar__progress.is-rest {
      background-color: #e94444; }
    .timer-bar__progress.is-complete {
      background-color: #3CDA3C !important; }
  .timer-bar__action {
    flex: none;
    margin-left: .5rem; }
    .timer-bar__action .icon-btn svg {
      width: 20px;
      height: 20px; }

.active-workout {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 6rem;
  right: 1rem;
  padding: .5rem;
  border-radius: 30px;
  background-color: #D5DDCC;
  color: white;
  text-decoration: none;
  transition: right .1s ease-in-out;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12);
  z-index: 9; }
  .site-main.is-open .active-workout {
    right: calc(1rem + 111px); }
  @media screen and (min-width: 992px) {
    .active-workout {
      bottom: 1rem; } }
  .active-workout__close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: #eef1eb;
    margin-right: .5rem; }
    .active-workout__close svg {
      width: 12px;
      height: 12px;
      fill: #979797; }
  .active-workout__resume {
    font-size: .65rem;
    text-transform: uppercase;
    background-color: #bcc9ad;
    border-radius: 30px;
    margin-left: .5rem;
    padding: .5rem; }

@-webkit-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }
.workout-complete {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  align-items: center; }
  .workout-complete__title {
    font-weight: 500;
    font-size: 1.35rem;
    color: #7e7e7e; }
  .workout-complete__message {
    font-weight: 500;
    font-size: 1.2rem; }
  .workout-complete img {
    position: relative;
    width: 100%;
    max-width: 200px;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite; }
  .workout-complete .btn {
    margin-top: 2.5rem; }

.recipe-carousel {
  position: relative;
  padding-bottom: 1.5rem; }
  .home-container .recipe-carousel {
    margin-left: -1rem; }
  .recipe-carousel h2 {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0; }
    .recipe-carousel h2 a {
      font-size: 0.85rem;
      color: #D5DDCC;
      margin-left: auto;
      text-decoration: none; }
      .recipe-carousel h2 a svg {
        width: 10px;
        height: 10px;
        fill: #D5DDCC;
        margin-left: .5rem; }
  .recipe-carousel .react-multi-carousel-item {
    margin-right: 1rem !important;
    border-radius: 8px;
    overflow: hidden; }

.recipe-carousel-card {
  display: block;
  width: 100%;
  height: 186px;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  margin-right: 1rem;
  background-color: #C5C5C5;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.01), 0 3px 6px 0 rgba(167, 167, 167, 0.04); }
  @media screen and (min-width: 992px) {
    .recipe-carousel-card {
      height: 220px; } }
  .recipe-carousel-card--sml {
    height: 123px; }
    @media screen and (min-width: 992px) {
      .recipe-carousel-card--sml {
        height: 220px; } }
  .recipe-carousel-card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) calc(100% - 86px), black 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .collection .recipe-carousel-card__overlay {
      height: 50%; }
  .recipe-carousel-card__title {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: .85rem;
    padding: .75rem;
    z-index: 1;
    color: white;
    font-weight: 600;
    line-height: 1.2; }
    .collection .recipe-carousel-card__title {
      padding: 1.25rem; }
    .recipe-carousel-card--sml .recipe-carousel-card__title {
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
  .recipe-carousel-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none; }
  .recipe-carousel-card.collection {
    display: block;
    height: 190px; }
  .recipe-carousel-card.small {
    display: block;
    height: 136px; }

.guide-carousel .react-multi-carousel-item {
  margin-right: 1rem !important; }

.guide-carousel .react-multi-carousel-list,
.recipe-carousel .react-multi-carousel-list {
  width: calc(100vw + 1.5rem);
  left: -1rem;
  padding-left: 2rem; }

.guide-carousel .react-multi-carousel-track {
  padding: 1rem 0; }

.guide-carousel-card {
  position: relative;
  display: block;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  min-height: 268px;
  text-decoration: none;
  color: #707070;
  margin-right: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.01), 0 3px 6px 0 rgba(167, 167, 167, 0.04); }
  .guide-carousel-card__image {
    position: relative;
    display: block;
    border-radius: 20px;
    overflow: hidden;
    height: 154px;
    z-index: 1; }
    .guide-carousel-card__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .guide-carousel-card__content {
    position: relative;
    background-color: white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 2.5rem 1rem 1rem;
    margin-top: -1.5rem; }
    .home-container .guide-carousel-card__content {
      background-color: #D6D6D6; }
  .guide-carousel-card__title {
    font-size: 1rem;
    font-weight: 700; }
    .home-container .guide-carousel-card__title {
      color: white; }
  .guide-carousel-card__details {
    font-size: .75rem;
    color: #D5DDCC; }
    .home-container .guide-carousel-card__details {
      display: inline;
      padding: 4px;
      background-color: white; }
  .guide-carousel-card__action {
    position: relative;
    display: flex;
    justify-content: flex-end; }
    .guide-carousel-card__action svg {
      width: 22px;
      height: 22px;
      fill: #707070; }
      .home-container .guide-carousel-card__action svg {
        fill: white; }

.image-editor {
  position: relative;
  width: 100%;
  height: 220px;
  background-color: #ECECEC;
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden; }
  .image-editor img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .image-editor input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }

.feature-list {
  list-style-type: disc;
  padding-left: 1rem; }
  .feature-list li {
    position: relative;
    padding: .5rem 0; }

.price {
  position: relative;
  display: block;
  text-align: center;
  font-size: 1.25rem;
  padding: 1rem 0 0; }
  .price span {
    color: #D5DDCC;
    font-size: 1rem; }

.icon-dropdown {
  position: relative; }
  .profile-card .icon-dropdown {
    position: absolute;
    top: 1rem;
    right: 1rem; }
  .icon-dropdown__trigger {
    position: relative;
    background-color: transparent;
    border: none;
    padding: 0; }
    .icon-dropdown__trigger svg {
      width: 26px;
      height: 26px;
      fill: #979797; }
      .icon-dropdown.small .icon-dropdown__trigger svg {
        width: 18px;
        height: 18px; }
  .icon-dropdown__menu {
    position: absolute;
    padding: .75rem 0;
    border-radius: .5rem;
    background-color: white;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.025);
    border: 1px solid #ECECEC;
    visibility: hidden;
    z-index: 1; }
    .icon-dropdown__trigger[aria-expanded=true] + .icon-dropdown__menu {
      visibility: visible; }
    .icon-dropdown__menu a,
    .icon-dropdown__menu button {
      position: relative;
      display: block;
      text-decoration: none;
      color: #979797;
      padding: .5rem .75rem;
      border: none;
      background-color: transparent; }
      .icon-dropdown__menu a:hover,
      .icon-dropdown__menu button:hover {
        background-color: #979797;
        color: white; }
    .icon-dropdown.right .icon-dropdown__menu {
      right: 0; }
    .icon-dropdown.center .icon-dropdown__menu {
      right: 50%;
      transform: translateX(-50%); }

.vegan-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: .75rem;
  right: .75rem;
  color: white;
  background-color: #24bf24;
  border-radius: 50%;
  width: 24px;
  height: 24px; }
  .vegan-icon span {
    line-height: 1;
    font-size: .85rem; }
  .vegan-icon__tooltip {
    position: absolute;
    top: -26px;
    left: calc(50% - 50px);
    width: 100px;
    font-size: 0.75rem;
    padding: .25rem;
    border-radius: 2px;
    background-color: rgba(126, 126, 126, 0.85);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in-out; }
  .vegan-icon:hover .vegan-icon__tooltip {
    visibility: visible;
    opacity: 1; }
    .recipe-carousel-card .vegan-icon:hover .vegan-icon__tooltip {
      visibility: hidden;
      opacity: 0; }
  .recipe-detail .vegan-icon {
    top: 1rem;
    right: 1rem;
    width: 30px;
    height: 30px; }

.nutrition-target {
  position: relative; }
  .nutrition-target h2 {
    margin-bottom: 1.25rem; }
    .nutrition-card .nutrition-target h2 {
      display: none; }
  .nutrition-card .nutrition-target {
    padding: 0 1rem;
    color: white; }

.nutrition-target-item {
  position: relative;
  margin-bottom: 1rem; }
  .nutrition-target-item__label {
    position: relative;
    display: flex;
    margin-bottom: .75rem; }
    .nutrition-target-item__label span {
      margin-left: auto; }
  .nutrition-target-item__progress {
    position: relative;
    width: 100%;
    height: 16px;
    border-radius: 30px;
    background-color: #ECECEC;
    overflow: hidden; }
  .nutrition-target-item__bar {
    position: relative;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    background: #979797;
    transition: width .2s ease-in-out; }
    .nutrition-target-item__bar.kcals {
      background: #ffe785;
      background: linear-gradient(90deg, #ffe785 0%, #ff9823 100%); }
    .nutrition-target-item__bar.protein {
      background: #fbb3b6;
      background: linear-gradient(90deg, #fbb3b6 0%, #fe767c 100%); }
    .nutrition-target-item__bar.carbohydrates {
      background: #abf1ff;
      background: linear-gradient(90deg, #abf1ff 0%, #0ac1e4 100%); }
    .nutrition-target-item__bar.fats {
      background: #86f586;
      background: linear-gradient(90deg, #86f586 0%, #2ac12a 100%); }

.nutrition-diary-card {
  position: relative;
  display: flex;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  .nutrition-diary-card__image {
    position: relative;
    flex: none;
    width: 52px;
    height: 52px;
    background-color: #ECECEC;
    border-radius: 8px;
    overflow: hidden; }
    .nutrition-diary-card__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .nutrition-diary-card__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding-left: 1rem; }
  .nutrition-diary-card__title {
    font-size: .85rem;
    font-weight: 500; }
  .nutrition-diary-card__nutrition {
    display: flex;
    align-items: center;
    margin-top: auto; }
  .nutrition-diary-card__stat {
    display: flex;
    align-items: center;
    font-size: .75rem;
    margin-right: .75rem; }
    .nutrition-diary-card__stat span {
      width: 12px;
      height: 12px;
      background-color: #979797;
      border-radius: 50%;
      margin-right: .5rem; }
    .nutrition-diary-card__stat.protein span {
      background-color: #D5DDCC; }
    .nutrition-diary-card__stat.carbohydrates span {
      background-color: #0DD8FF; }
    .nutrition-diary-card__stat.fats span {
      background-color: #3CDA3C; }

.category-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem; }

.category-grid-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 220px;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  text-decoration: none;
  color: #707070;
  font-weight: 500;
  line-height: 1.2;
  overflow: hidden;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.01), 0 3px 6px 0 rgba(167, 167, 167, 0.04); }
  .category-grid-item.favourites {
    color: #D5DDCC;
    border: 2px solid #D5DDCC; }
    .category-grid-item.favourites svg {
      fill: #D5DDCC; }
  .category-grid-item svg {
    position: absolute;
    top: 1rem;
    right: .5rem;
    width: 24px;
    height: 24px;
    fill: white; }
  .category-grid-item__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .category-grid-item__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .category-grid-item__inner {
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem; }

.metric-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap; }

.metric-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  color: white;
  margin-bottom: 1rem;
  flex-grow: 1;
          flex-shrink: 0;
          flex-basis: calc(50% - .75rem); }
  .metric-item:nth-child(2n + 1) {
    margin-right: 1rem; }
  .metric-item.is-selected {
    background-color: white;
    color: #D5DDCC; }
  @media screen and (min-width: 768px) {
    .metric-item {
      flex-grow: 1;
              flex-shrink: 0;
              flex-basis: calc(33% - 1rem);
      max-width: calc(33% - 1rem); }
      .metric-item:nth-child(2n + 1) {
        margin-right: 0; }
      .metric-item:nth-child(3n + 1) {
        margin-right: 1rem; }
      .metric-item:nth-child(3n + 3) {
        margin-left: 1rem; } }
  .metric-item__inner {
    padding: 0 1rem; }
  .metric-item__label {
    font-size: 1rem; }
  .metric-item__main {
    margin-top: .75rem; }
  .metric-item__value {
    font-size: 1.25rem;
    font-weight: 500; }
  .metric-item__unit {
    opacity: .75;
    margin-left: .25rem; }

.challenge-container {
  position: relative;
  margin-top: -166px; }
  .challenge-container__inner {
    position: relative;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    width: calc(100vw - 1.5rem);
    margin-left: .75rem;
    margin-top: -150px;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
    @media screen and (min-width: 992px) {
      .challenge-container__inner {
        margin-top: 0;
        width: 100%; } }
  @media screen and (min-width: 992px) {
    .challenge-container {
      margin-top: 0; } }

.challenge-exercise__video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: white; }
  .challenge-exercise__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .challenge-exercise__video button {
    position: relative;
    z-index: 1; }
  @media screen and (min-width: 992px) {
    .challenge-exercise__video {
      width: auto;
      height: auto; } }

.play-btn {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: transparent;
  border: none; }
  .play-btn svg {
    position: relative;
    width: 80px;
    height: 80px;
    fill: #D5DDCC; }
  .play-btn--pause {
    position: absolute !important;
    bottom: 170px;
    right: 1rem; }
    .play-btn--pause svg {
      width: 30px;
      height: 30px; }

@media screen and (min-width: 992px) {
  .workout-exercise--challenge {
    display: grid;
    grid-template-columns: 300px auto; } }

/* Tab Nav */
.tab-nav {
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  overflow-x: auto;
  margin: 0;
  padding: 0 1rem;
  left: -1rem;
  margin-bottom: 1rem; }
  .tab-nav__item {
    position: relative;
    display: inline-block;
    margin-right: 1rem; }
    .tab-nav__item a {
      position: relative;
      display: block;
      padding: .5rem 1rem;
      border-radius: 30px;
      background-color: white;
      color: #D5DDCC;
      font-weight: bold;
      text-decoration: none; }
      .tab-nav__item a.is-active {
        background-color: #D5DDCC;
        color: white;
        border: 2px solid white; }

/* Challenge Result List Styling */
.result-item {
  position: relative;
  width: 100%;
  margin-bottom: 1rem; }
  .result-item__header {
    position: relative;
    padding: .5rem;
    background-color: white;
    border-radius: .5rem;
    font-weight: bold; }
    .result-item__header p {
      margin: 0;
      display: flex;
      align-items: center; }
  .result-item__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem;
    background-color: lightgrey; }
    .result-item__content div:hover:after {
      content: attr(data-date);
      background-color: yellow; }
    .result-item__content img {
      position: relative;
      flex: none;
      max-width: 160px;
      height: auto;
      margin: 1rem;
      object-fit: contain; }

.countdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #D5DDCC;
  margin: 0 auto; }
  .countdown__inner {
    font-weight: bold;
    color: #D5DDCC; }
    .countdown__inner span {
      font-size: 2rem; }

.superset-actions {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 2rem 0 1rem; }
  .superset-actions__col {
    display: flex;
    justify-content: center;
    align-items: center; }

.round-header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem; }
  .round-header .workout-label {
    position: absolute;
    left: 0; }
  .round-header__number {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 0.85rem; }
  .round-header__current {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #D5DDCC;
    margin: 0 4px; }

.horizontal-datepicker .react-datepicker__input-container {
  display: flex;
  justify-content: center; }
  .horizontal-datepicker .react-datepicker__input-container input {
    width: auto;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    font-weight: bold;
    text-align: center;
    border: none;
    height: 38px;
    margin-bottom: .5rem;
    text-transform: uppercase;
    font-size: .85rem; }

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  height: 40px;
  line-height: 40px; }

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.react-datepicker__navigation {
  top: 5px; }

